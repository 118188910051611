<template>
  <div>
    <el-dialog :title="title" :visible.sync="open" width="800px" @close="commitOnClose">
      <el-form ref="saveOrEditForm" :model="saveData" label-width="80px" style="width:600px">
        <el-form-item label="sn号" prop="sn">
          <template>
            {{ saveData.sn }}
          </template>
        </el-form-item>
        <el-form-item label="活动名称" prop="activityName">
          {{ saveData.activityName }}
        </el-form-item>
        <el-form-item label="商户编号" prop="merchantNo">
          <el-input v-model="saveData.merchantNo" placeholder="请输入商户编号" @keyup.enter.native="getMerchantName" @blur="getMerchantName" />
        </el-form-item>
        <el-form-item label="商户名称" prop="merchantName">
          {{ merchantName }}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" :loading="loading">提交</el-button>
        <el-button type="primary" @click="cancel">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { TerminalApi } from '@/api'
export default {
  name: 'CustomForm',
  props: {
    dicts: {
      type: Object,
      default: function () {
        return {}
      },
    },
    selectRow: {
      type: Object,
      default: function () {
        return {}
      },
    },
    onFresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: '',
      saveData: {},
      open: false,
      loading: false,
      options: [],
      merchantNo: '',
      merchantName: '',
			status: true
    }
  },
  watch: {
    onFresh: function (value) {
      this.open = value
      if (value) {
        this.resetData()
      }
    },
  },
  mounted() {},
  methods: {
    async resetData() {
      this.title = '机具绑定'
      this.merchantName = ''
      this.saveData = this.selectRow
    },
    async submitForm() {
      if (!this.merchantName) {
        this.Message.error('请输入正确的商户编号')
        return
      }
      this.loading = true
      let result = await TerminalApi.terminalInfo.bindSn(
        this.saveData.sn,
        this.saveData.merchantNo
      )
      this.loading = false
      if (result.success) {
        this.Message.success('绑定成功')
        this.commitOnClose()
      } else {
        this.Message.error(result.message)
      }
    },
    cancel() {
      this.saveData = {}
      this.merchantName = ''
      this.commitOnClose()
    },
    commitOnClose() {
      this.$emit('on-close')
    },
    async getActivityName(val) {
      let result = await TerminalApi.terminalInfo.getActivityName(val)
      this.activetyList = result.success ? result.data : ''
    },
    async getMerchantName() {
      let res = await TerminalApi.terminalInfo.queryMerchantName(
        this.saveData.merchantNo
      )
      if (res.success) {
        this.merchantName = res.data.merchantName
      } else {
        this.merchantName = ''
        this.Message.error(res.message)
      }
    },
    // delayGetName() {
    //   var that = this
    //   if (!that.status) {
    //     return
    //   }
    //   that.status = false
    //   setTimeout(function () {
    //     that.getMerchantName()
    //     that.status = true
    //   }, 1500)
    // },
  },
}
</script>

<style scoped></style>
